/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <aside class="sidenav">
    <div class="sidenav-header">
      <img class="sidenav-logo" src="~@/assets/images/logo.png" alt="" srcset=""/>
      <div class="sidenav__close-icon">
        <icon feather="x"></icon>
      </div>
    </div>
    <ul class="sidenav_list">
      <li class="sidenav_list-title">Mi menú</li>
      <li
        v-for="item in validItems"
        :key="item.title"
        class="sidenav_list-item"
        :class="item.submenu ? 'has-sub' : ''"
      >
        <template v-if="item.submenu">
          <a href="#" @click="toggleSub($event)">
            <icon :feather="item.icon"></icon>
            <span>{{ item.title }}</span>
          </a>
          <ul class="sub-menu">
            <li v-for="(subitem, i) in item.submenu" :key="i" class="sidenav_list-item">
              <router-link
                :class="[subitem.domain, subitem.domain == $route.meta.domain ? 'active' : '']"
                :to="subitem.to"
              >
                <icon :feather="'circle'"></icon>
                <span>{{ subitem.title }}</span>
              </router-link>
            </li>
          </ul>
        </template>
        <template v-if="item.to">
          <router-link
            :class="[item.domain, item.domain == $route.meta.domain ? 'active' : '']"
            :to="item.to"
          >
            <i :data-feather="item.icon"></i> <span>{{ item.title }}</span>
          </router-link>
        </template>
      </li>
    </ul>
  </aside>
</template>
<script>
import Vuex, {mapActions} from "vuex";

export default {
  name: "SideNav",
  computed: {
    ...Vuex.mapState(["user", "notificacionesNoLeidas"]),
    validItems() {
      return this.items.filter((item) => {
        if (item.blockOnDisabled === true && this.user.persona.estado === 'Inhabilitado') {
          return false;
        }
        return !!(
          item.usersAvailable?.includes(this.user.id) ||
          item.valid?.includes(this.user.tipo) ||
          (item?.to?.name === 'CalendarPartesDiarios' && this.user.persona.permiso_parte?.can_create) ||
          (item?.to?.name === 'ListarPartesDiarios' && this.user.persona.permiso_parte) ||
          (item?.to?.name === 'ListGuardiasPorDia' && this.user.persona.permiso_guardia));
      });
    },
  },
  data() {
    return {
      items: [
        {
          title: "Inicio",
          icon: "home",
          to: {name: "Inicio"},
          domain: "Inicio",
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Mis Recibos",
          icon: "file-text",
          to: {name: "MisRecibos"},
          domain: "MisRecibos",
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: false
        },
        {
          title: "Mis notificaciones",
          icon: "bell",
          to: {name: "MisNotificaciones"},
          domain: "MisNotificaciones",
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: false
        },
        {
          title: "Liquidaciones",
          icon: "book",
          to: {name: "ListarLiquidaciones"},
          domain: "Liquidaciones",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Personas",
          icon: "users",
          to: {name: "ListarPersonas"},
          domain: "Personas",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Noticias",
          icon: "edit",
          to: {name: "ListarNoticias"},
          domain: "Noticias",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Admin Notificaciones",
          icon: "list",
          to: {name: "ListarNotificacionesAdmin"},
          domain: "NotificacionesAdmin",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Admin Procedimientos",
          icon: "list",
          to: {name: "ListarProcedimientosAdmin"},
          domain: "ProcedimientosAdmin",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Procedimientos",
          icon: "list",
          to: {name: "Procedimientos"},
          domain: "Procedimientos",
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Formularios",
          icon: "file",
          to: {name: "Formularios"},
          domain: "Formularios",
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Partes diarios",
          icon: "calendar",
          to: {name: "CalendarPartesDiarios"},
          domain: "ParteDiario",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Ver partes diarios",
          icon: "book-open",
          to: {name: "ListarPartesDiarios"},
          domain: "ListarPartesDiarios",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Guardias",
          icon: "clock",
          to: {name: "ListGuardiasPorDia"},
          domain: "ListGuardiasPorDia",
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Asig. familiares",
          icon: "archive",
          submenu: [
            {
              title: "Declaraciones juradas",
              to: {name: "ListarPercepciones"},
              domain: "ListarPercepciones",
            },
          ],
          valid: ["Empleado", "Jefe", "Gerente", "RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Licencias",
          icon: "archive",
          submenu: [
            {
              title: "Listar licencias",
              to: {name: "ListarLicencias"},
              domain: "ListarLicencias",
            },
            {
              title: "Ver por empleado",
              to: {name: "VerLicenciasPorEmpleado"},
              domain: "VerPorEmpleado",
            },
            {
              title: "Ver por tipo",
              to: {name: "VerLicenciasPorTipo"},
              domain: "VerPorTipo",
            },
            {
              title: "Ver por área",
              to: {name: "VerLicenciasPorArea"},
              domain: "VerPorArea",
            },
            {
              title: "Ver por gerencia",
              to: {name: "VerLicenciasPorGerencia"},
              domain: "VerPorGerencia",
            },
          ],
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
        {
          title: "Licencias",
          icon: "archive",
          submenu: [
            {
              title: "Ver por empleado",
              to: {name: "VerLicenciasPorEmpleado"},
              domain: "VerPorEmpleado",
            },
            {
              title: "Ver por tipo",
              to: {name: "VerLicenciasPorTipo"},
              domain: "VerPorTipo",
            },
          ],
          valid: ["Jefe"],
          blockOnDisabled: true
        },
        {
          title: "Licencias",
          icon: "archive",
          submenu: [
            {
              title: "Ver por empleado",
              to: {name: "VerLicenciasPorEmpleado"},
              domain: "VerPorEmpleado",
            },
            {
              title: "Ver por tipo",
              to: {name: "VerLicenciasPorTipo"},
              domain: "VerPorTipo",
            },
            {
              title: "Ver por área",
              to: {name: "VerLicenciasPorArea"},
              domain: "VerPorArea",
            },
          ],
          valid: ["Gerente"],
          blockOnDisabled: true
        },
        {
          title: "Otras tablas",
          icon: "layers",
          submenu: [
            {
              title: "Gerencias",
              icon: "square",
              to: {name: "ListarGerencias"},
              domain: "Gerencias",
            },
            {
              title: "Áreas",
              icon: "square",
              to: {name: "ListarAreas"},
              domain: "Areas",
            },
            {
              title: "Categorías",
              icon: "square",
              to: {name: "ListarCategorias"},
              domain: "Categorias",
            },
            {
              title: "Sucursales",
              icon: "square",
              to: {name: "ListarSucursales"},
              domain: "Sucursales",
            },
            {
              title: "Obras sociales",
              icon: "square",
              to: {name: "ListarObrasSociales"},
              domain: "ObrasSociales",
            },
            {
              title: "Permisos guardia",
              icon: "square",
              to: {name: "ListPermisoGuardia"},
              domain: "PermisoGuardia",
            },
            {
              title: "Permisos parte",
              icon: "square",
              to: {name: "ListPermisoParte"},
              domain: "PermisoParte",
            },
          ],
          valid: ["RRHH", "dev"],
          blockOnDisabled: true
        },
      ],
      usersForParte: [
        12, //DARÍO SCHULMEISTER
        59, //SILVIA PEREYRA
        30, //SANDRA RETAMAL
        4, //GUSTAVO JUAREZ
        25, //DANIEL FLORES
        14 //JULIO VOGLER
      ],
    };
  },
  updated() {
    this.classSubMenu();
  },
  async mounted() {
    this.sidenavHandler();
    this.classSubMenu();
    this.$router.afterEach(() => {
      setTimeout(() => {
        this.classSubMenu();
      }, 1);
      return;
    });
    await this.getNotificacionesNoLeidasAction()
    this.showNotificacionesNoLeidas()
  },
  methods: {
    sidenavHandler() {
      let menuIcon = document.querySelector(".menu-icon");
      let sidenavClose = document.querySelector(".sidenav__close-icon");
      let menuItems = document.querySelectorAll(".sidenav_list-item:not(.has-sub)");

      //Abrir
      menuIcon.addEventListener("click", () => {
        this.openNav();
      });

      //Cerrar
      menuItems.forEach((menuItem) => {
        menuItem.addEventListener("click", () => {
          this.closeNav();
        });
      });
      sidenavClose.addEventListener("click", () => {
        this.closeNav();
      });
    },
    openNav() {
      let sidenav = document.querySelector(".sidenav");
      let app = document.querySelector("#app");
      let overlay = document.createElement("div");
      overlay.classList.add("sidenav-overlay");
      sidenav.classList.add("active");
      app.appendChild(overlay);
      overlay.addEventListener("click", () => {
        this.closeNav();
      });
    },
    closeNav() {
      let sidenav = document.querySelector(".sidenav");
      let app = document.querySelector("#app");
      let overlay = document.querySelector("#app .sidenav-overlay");
      sidenav.classList.remove("active");
      if (overlay != null) {
        setTimeout(() => {
          app.removeChild(overlay);
        }, 500);
      }
    },
    toggleSub(e) {
      let itemMenu = e.target.closest("li.sidenav_list-item.has-sub");
      if (itemMenu) {
        if (itemMenu.classList.contains("opened")) {
          itemMenu.classList.remove("opened");
        } else {
          itemMenu.classList.add("opened");
        }
      }
    },
    classSubMenu() {
      let items = document.querySelectorAll("li.sidenav_list-item.has-sub.opened");
      items.forEach((item) => {
        item.classList.remove("opened");
      });
      let linkActive = document.querySelector(".sidenav_list-item>a.active");
      if (linkActive) {
        let itemList = linkActive.closest("li.sidenav_list-item.has-sub");
        if (itemList) {
          if (!itemList.classList.contains("opened")) {
            itemList.classList.add("opened");
          }
        }
      }
    },
    ...mapActions({
      getNotificacionesNoLeidasAction: "getNotificacionesNoLeidas",
    }),
    showNotificacionesNoLeidas(){
      this.$nextTick(() => {
        const misNotificacionesLink = document.querySelector('.MisNotificaciones')
        if (misNotificacionesLink && this.notificacionesNoLeidas) {
          misNotificacionesLink.style.position = 'relative'
          const divNoLeido = document.createElement("div");
          divNoLeido.style.position = 'absolute';
          divNoLeido.style.boxShadow = '0px 0px 4px red';
          divNoLeido.style.top = '12px'
          divNoLeido.style.right = '15px'
          divNoLeido.style.width = '8px'
          divNoLeido.style.height = '8px'
          divNoLeido.style.backgroundColor = 'red'
          divNoLeido.style.borderRadius = '10px'
          misNotificacionesLink.appendChild(divNoLeido)
        }
      })
    }
  },
};
</script>
